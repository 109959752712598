var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-class px-0"},[_c('div',{staticClass:"row hidden-card-item text-roboto"},[_c('div',{staticClass:"col-12 ml-1 mb-1",class:{ hidden: _vm.order.sizeLargerHidden }},[_c('mdb-tooltip',{attrs:{"trigger":"hover","options":{ placement: 'right' }}},[_c('mdb-icon',{staticClass:"pr-2",attrs:{"slot":"reference","icon":"expand-arrows-alt","size":"lg"},slot:"reference"}),_c('span',{attrs:{"slot":"tip"},slot:"tip"},[_vm._v("Размер больше")])],1),_c('span',[_vm._v(" "+_vm._s(_vm.order.sizeLarger))])],1),_c('div',{staticClass:"col-12 ml-1 mb-1"},[_c('mdb-tooltip',{attrs:{"trigger":"hover","options":{ placement: 'right' }}},[(
            _vm.status === 'Передан дизайнеру' ||
            _vm.status === 'Правки' ||
            _vm.status === 'Черновик'
          )?_c('mdb-icon',{staticClass:"date_maket_icon zoom_item pr-1",staticStyle:{"cursor":"pointer"},attrs:{"slot":"reference","icon":"user-edit","size":"lg"},nativeOn:{"click":function($event){_vm.modal_date_date = true}},slot:"reference"}):_c('mdb-icon',{staticClass:"pr-1",attrs:{"slot":"reference","icon":"user-edit","size":"lg"},slot:"reference"}),_c('span',{attrs:{"slot":"tip"},slot:"tip"},[_vm._v("Дата готовности макета")]),_c('mdb-modal',{staticClass:"mt-5",attrs:{"size":"md","show":_vm.modal_date_date},on:{"close":function($event){_vm.modal_date_date = false}}},[_c('mdb-modal-header',{staticClass:"text-center"},[_c('mdb-modal-title',[_vm._v("Не успеваю закончить макет")])],1),_c('mdb-modal-body',{staticClass:"my-3"}),_c('mdb-modal-footer',{staticClass:"justify-content-around"},[_c('mdb-btn',{attrs:{"color":"success"},nativeOn:{"click":function($event){return _vm.updateOrder(_vm.order, 119139, 0, true)}}},[_vm._v("Перенести заказ ")])],1)],1)],1),_c('span',{staticClass:"test-text",class:{
          dateMaketToday: _vm.order.dateToday,
          dateMaketFire: _vm.order.dateFire,
        }},[_vm._v(_vm._s(_vm.order.dateMaket.valueAsText))])],1),_c('div',{staticClass:"col-12 ml-1 mb-1",class:{ hidden: _vm.order.datePlanHidden }},[_c('mdb-tooltip',{attrs:{"trigger":"hover","options":{ placement: 'right' }}},[_c('mdb-icon',{staticClass:"date_plan_icon pr-1",attrs:{"slot":"reference","icon":"user-clock","size":"lg"},slot:"reference"}),_c('span',{attrs:{"slot":"tip"},slot:"tip"},[_vm._v("Дата план ")])],1),_c('span',{staticClass:"pr-1"},[_vm._v(_vm._s(_vm.order.datePlan))])],1),_c('div',{staticClass:"col ml-1 mb-1"},[_c('mdb-tooltip',{attrs:{"trigger":"hover","options":{ placement: 'right' }}},[_c('mdb-icon',{staticClass:"pr-1",attrs:{"slot":"reference","icon":"user-friends","size":"lg"},slot:"reference"}),_c('span',{attrs:{"slot":"tip"},slot:"tip"},[_vm._v("Менеджер")])],1),_c('span',{},[_vm._v(_vm._s(_vm.order.manager))])],1),_c('div',{staticClass:"w-100"}),_c('div',{staticClass:"col ml-1"},[_c('mdb-tooltip',{attrs:{"trigger":"hover","options":{ placement: 'right' }}},[_c('mdb-icon',{staticClass:"pr-1",attrs:{"slot":"reference","icon":"truck","size":"lg"},slot:"reference"}),_c('span',{attrs:{"slot":"tip"},slot:"tip"},[_vm._v("Число отправки и способ доставки")])],1),_c('span',[_vm._v(_vm._s(_vm.order.dateShip))]),_c('br'),_c('span',{staticClass:"span_delivery"},[_vm._v(_vm._s(_vm.order.deliveryMethod))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }