<template>
  <div>
    <div>
      <h6
        style="cursor: default"
        class="justify-content-center card card-status text-center mb-2"
      >
        {{ status }}
      </h6>
    </div>

    <div v-for="order in renderNormalView" :key="order.id">
      <div
        :class="{ hidden: !order.dateBlock }"
        class="span_date_sortable ml-2 my-2"
      >
        {{ order.dateMaket.valueAsText }}
      </div>

      <div
        :class="{ hidden: !order.overUrgentBlock }"
        class="span_date_sortable ml-2 my-2"
      >
        Срочные заказы
      </div>

      <div
        :class="{ hidden: !order.urgentBlock }"
        class="span_date_sortable ml-2 my-2"
      >
        Приоритетные заказы
      </div>

      <div
        class="hidden-card card effect3 card-anim mt-1 mx-1"
        :class="{ smenshik: order.smenshik }"
      >
        <Card
          class="card-background text-left"
          :order="order"
          :status="status"
          list-group-item
        />
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/app/Card";
import draggable from "vuedraggable";
export default {
  props: ["orders", "status", "worker"],
  components: {
    Card,
    draggable,
  },
  data() {
    return {
      hidden_statuses_card_status: true,
      isShow: true,
    };
  },
  computed: {
    selectedOrders() {
      const usedWorker = this.worker;
      const usedStatus = this.status;
      if (usedWorker === "0") {
        // отдаем заказы для всех
        return this.orders.filter((item) => {
          if (item.orderStatus.name.indexOf(usedStatus) !== -1) {
            return true;
          }
        });
      }
      let workersList = usedWorker.split(" , ");
      let orders1111 = []; // хранит заказы на одного дизайнера
      let ordersManyWorker = []; // прибавляет orders1111
      for (let j = 0, k = workersList.length; j < k; j++) {
        // если дизайнеров несколько, будет сортировать их заказы по очереди
        orders1111 = this.orders.filter((item) => {
          for (let i = 0, l = item.customFields.length; i < l; i += 1) {
            const field = item.customFields[i];
            if (
              j === 0 ||
              usedStatus === "Правки" ||
              usedStatus === "Черновик" ||
              usedStatus === "Печать СПб" ||
              usedStatus === "Согласование"
            ) {
              if (
                field.fieldId === 85 &&
                field.value === workersList[j] &&
                item.orderStatus.name.indexOf(usedStatus) !== -1
              ) {
                return true;
              }
            }
          }
          return false;
        });
        ordersManyWorker = ordersManyWorker.concat(orders1111);
      }
      return ordersManyWorker;
    },
    normalView() {
      const orders = this.selectedOrders;
      const usedWorker = this.worker;
      const workersList = usedWorker.split(" , ");
      if (orders) {
        return orders.map((item, index) => {
          const res = {
            id: item.internalNumber,
            days: "",
            basket: [],
            comment: "",
            commentLink: [],
            dateMaket: {
              value: "",
              valueAsText: "",
            },
            datePlan: "",
            dateShip: "",
            deliveryMethod: "",
            manager: "",
            maket1: "",
            maket2: [],
            sizeLarger: "",
            clientId: item.customer.id,

            managerHidden: false,
            urgent: false,
            overUrgent: false,
            dateShipHidden: false,
            datePlanHidden: false,
            sizeLargerHidden: false,
            dateToday: false,
            dateFire: false,
            dateBlock: false,
            overUrgentBlock: false,
            urgentBlock: false,
            smenshik: false,
          };
          if (item.manager) {
            res.manager = item.manager.fullName;
          }
          // переберам все кастом фаилд
          for (let i = 0, l = item.customFields.length; i < l; i += 1) {
            const field = item.customFields[i];
            switch (field.fieldId) {
              case 86: {
                // дата макета
                let dateMaketkorotko = field.valueAsText;
                let dateMaketkorotko2 = dateMaketkorotko.substring(
                  0,
                  dateMaketkorotko.length - 5
                );
                res.dateMaket.valueAsText = dateMaketkorotko2;
                res.dateMaket.value = field.value;
                let dateMaketText = field.value;
                let dateMaketDMY = dateMaketText.split(".");
                let dateMaketDate = new Date(
                  dateMaketDMY[2],
                  dateMaketDMY[1] - 1,
                  dateMaketDMY[0]
                );
                if (
                  dateMaketDate.getTime() === today.getTime() &&
                  (this.status.indexOf("Передан дизайнеру") !== -1 ||
                    this.status.indexOf("Правки") !== -1 ||
                    this.status.indexOf("Черновик") !== -1)
                ) {
                  res.dateToday = true;
                  res.urgent = true;
                  indexUrgent.push(index);
                }
                if (
                  dateMaketDate.getTime() < today.getTime() &&
                  (this.status.indexOf("Передан дизайнеру") !== -1 ||
                    this.status.indexOf("Правки") !== -1 ||
                    this.status.indexOf("Черновик") !== -1)
                ) {
                  res.dateFire = true;
                  res.urgent = true;
                  indexUrgent.push(index);
                }
                break;
              }
              case 677: {
                // дата план
                let datePlankorotko = field.valueAsText;
                let datePlankorotko2 = datePlankorotko.substring(
                  0,
                  datePlankorotko.length - 5
                );
                res.datePlan = datePlankorotko2;
                break;
              }
              case 88: {
                // дата доставки
                let dateshipkorotko = field.valueAsText;
                let dateshipkorotko2 = dateshipkorotko.substring(
                  0,
                  dateshipkorotko.length - 5
                );
                res.dateShip = dateshipkorotko2;
                break;
              }
              case 87: {
                // метод доставки
                res.deliveryMethod = field.valueAsText;
                break;
              }
              case 1303: {
                // размер больше
                res.sizeLarger = field.valueAsText;
                break;
              }
              case 850: {
                // макет 1
                res.maket1 = field.value;
                break;
              }
              case 1779: {
                // макет 2
                let maket2 = [];
                let maket2_bs = field.value;
                if (maket2_bs.indexOf("https://") !== -1) {
                  let maket2_List = maket2_bs.split("https");
                  for (let i = 0, l = maket2_List.length; i < l; i++) {
                    if (maket2_List[i].indexOf("://") !== -1) {
                      let maket2_I = maket2_List[i];
                      let maket2_I_List = maket2_I.split(" ");
                      let link = maket2_I_List[0];
                      let linkList = link.split("<br/>");
                      maket2.push({
                        linkMaket: `https${linkList[0]}`,
                        num: i + 1,
                      });
                    }
                  }
                }
                res.maket2 = maket2;
                break;
              }
              case 85: {
                // дизайнер
                if (workersList.length > 1 && field.value === workersList[1])
                  res.smenshik = true;
                break;
              }
            }
          }

          // если нет даты план и доставки скрываем значки
          if (!res.datePlan.length) {
            res.datePlanHidden = true;
          }
          if (!res.sizeLarger.length) {
            res.sizeLargerHidden = true;
          }
          if (!res.dateShip.length && !res.deliveryMethod) {
            res.dateShipHidden = true;
          }

          // сколько дней заказ в работе
          let dateStr = item.date;
          let dateDMY = dateStr.split(".");
          let dateDate = new Date(dateDMY[2], dateDMY[1] - 1, dateDMY[0]);
          let days = Math.round(
            (Date.parse(today) - Date.parse(dateDate)) / 86400000
          );
          if (days <= 20) {
            switch (days) {
              case 0:
                days = `Новый заказ`;
                break;
              case 1:
                days = `${days} день`;
                break;
              case 2:
              case 3:
              case 4:
                days = `${days} дня`;
                break;
              default:
                days = `${days} дней`;
                break;
            }
          } else {
            switch (days % 10) {
              case 1:
                days = `${days} день`;
                break;
              case 2:
              case 3:
              case 4:
                days = `${days} дня`;
                break;
              default:
                days = `${days} дней`;
                break;
            }
          }
          res.days = days;

          // если комментарий ссылка
          if (item.internalComments.indexOf("https://") !== -1) {
            let commentWithLink = item.internalComments;
            let commentWithLinkList = commentWithLink.split("https");
            let commentLink = [];
            let commentStr = "";
            for (let i = 0, l = commentWithLinkList.length; i < l; i++) {
              if (commentWithLinkList[i].indexOf("://") !== -1) {
                let commentWithLinkI = commentWithLinkList[i];
                let commentWithLinkListI = commentWithLinkI.split(" ");
                let link = commentWithLinkListI[0];
                let linkList = link.split("<br/>");
                commentLink.push(`https${linkList[0]}`);
                if (commentWithLinkListI.length > 1) {
                  commentStr =
                    commentStr +
                    link.slice(linkList[0].length + 5) +
                    commentWithLinkI.slice(commentWithLinkListI[0].length + 1);
                }
              } else {
                commentStr = commentStr + commentWithLinkList[i];
              }
            }
            res.commentLink = commentLink;
            res.comment = commentStr;
          } else {
            res.comment = item.internalComments;
          }

          // если заказ срочный, а так же выше по датам urgent
          if (
            (item.internalComments.indexOf("сроч") !== -1 ||
              item.internalComments.indexOf("Сроч") !== -1 ||
              item.internalComments.indexOf("СРОЧ") !== -1) &&
            (this.status.indexOf("Передан дизайнеру") !== -1 ||
              this.status.indexOf("Правки") !== -1 ||
              this.status.indexOf("Черновик") !== -1)
          ) {
            res.urgent = false;
            res.overUrgent = true;
            indexUrgent.pop();
            indexFire.push(index);
          }

          // корзина
          let basket = [];
          for (let i = 0, l = item.goodsPositions.length; i < l; i += 1) {
            const goodsItem = item.goodsPositions[i];
            const itemPoint = String(goodsItem.goods.marking);
            if (!hiddenGoods.includes(itemPoint)) {
              basket.push({
                product: `${goodsItem.goods.marking}`,
                productQ: `${goodsItem.quantity}`,
                numberBasket: i,
              });
            }
          }
          res.basket = basket;
          return res;
        });
      } else return 0;
    },
    sortNormalView() {
      const orders = this.normalView;
      let indexUrgentOrders = [];
      let indexFireOrders = [];

      for (let i in indexUrgent) {
        indexUrgentOrders.push(orders[indexUrgent[i]]);
      }
      for (let i in indexFire) {
        indexFireOrders.push(orders[indexFire[i]]);
      }
      indexUrgent = [];
      indexFire = [];

      orders.sort(function (a, b) {
        let dateAStr = a.dateMaket.value;
        let dateAList = dateAStr.split(".");
        let dateA = new Date(dateAList[2], dateAList[1] - 1, dateAList[0]);
        let dateBStr = b.dateMaket.value;
        let dateBList = dateBStr.split(".");
        let dateB = new Date(dateBList[2], dateBList[1] - 1, dateBList[0]);
        if (isNaN(Date.parse(dateB))) dateB = new Date("01.01.2020");
        if (isNaN(Date.parse(dateA))) dateA = new Date("01.01.2020");
        return dateA - dateB;
      });

      for (let i in indexUrgentOrders) {
        indexUrgent.push(orders.indexOf(indexUrgentOrders[i]));
      }
      for (let i in indexFireOrders) {
        indexFire.push(orders.indexOf(indexFireOrders[i]));
      }
      return orders;
    },
    renderNormalView() {
      const orders = this.sortNormalView;
      if (
        orders.length > 0 &&
        (this.status === "Передан дизайнеру" ||
          this.status === "Правки" ||
          this.status === "Черновик")
      ) {
        // ставим в начало Срочные заказы
        for (let i = 0, l = indexUrgent.length; i < l; i++) {
          orders.unshift(...orders.splice(indexUrgent[i], 1));

          if (i === l - 1) {
            orders[0].urgentBlock = true;
          }

          // если Срочный заказ шел после Очень Срочных меняем их индексы
          // тк Срочный поднимается наверх и все индексы перед ним получат +1
          for (let j = i + 1, l = indexUrgent.length; j < l; j++) {
            if (indexUrgent[i] > indexUrgent[j])
              indexUrgent[j] = indexUrgent[j] + 1;
          }

          for (let j = 0, l = indexFire.length; j < l; j++) {
            if (indexUrgent[i] > indexFire[j]) indexFire[j] = indexFire[j] + 1;
          }
        }
        // ставим в начало Очень Срочные заказы
        for (let i = 0, l = indexFire.length; i < l; i++) {
          orders.unshift(...orders.splice(indexFire[i], 1));
          for (let j = i + 1, l = indexFire.length; j < l; j++) {
            if (indexFire[i] > indexFire[j]) indexFire[j] = indexFire[j] + 1;
          }

          if (i === l - 1) {
            orders[0].overUrgentBlock = true;
          }
        }
      }
      if (orders.length > 1 && this.status === "Передан дизайнеру") {
        let srochnyeZakazy = indexUrgent.length + indexFire.length;
        if (orders.length - srochnyeZakazy === 1)
          orders[srochnyeZakazy].dateBlock = true;
        for (let i = srochnyeZakazy, l = orders.length; i < l - 1; i++) {
          if (i === srochnyeZakazy) orders[i].dateBlock = true;
          let orderA = orders[i];
          let orderB = orders[i + 1];
          if (orderA.dateMaket.value < orderB.dateMaket.value) {
            orders[i + 1].dateBlock = true;
          }
        }
      }
      indexUrgent = [];
      indexFire = [];
      return orders;
    },
  },
};
const hiddenGoods = [
  "001 Рама(серебро)",
  "002 Рама(золото)",
  "003 Рама(белая)",
  "004 Рама(кремовая)",
  "insta",
  "Гель",
  "Дерево 20 на 30",
  "Дерево 40х30",
  "доставка в подарок",
  "Ж2Ч",
  "Крючок",
  "лак",
  "Наушники беспроводные  не нужен",
  "Рама",
  "Сделать Фотографию Картины",
  "Сертификат",
  "Скетч6040",
  "УП  Карона",
  "УП 1 - ЁЛКИ",
  "УП 2 - Дед Мороз",
  "УП 3 - 2020",
  "УП 4 - ИГРУШКИ",
  "УП Бабочки",
  "УП именная",
  "УП Подарки",
  "УП_001",
  "Упаковка Цветы",
  "Скидка Менеджер",
  "Упаковка в подарок",
  "Дед Мороз 3 упаковка",
  "Нашел дешевле",
];
let indexFire = [];
let indexUrgent = [];
var today = new Date();
today.setHours(0, 0, 0, 0);
</script>

