<template>
  <div>
    <Navbar />
    <div class="container-fluid">
      <div
        v-if="orders && orders.length && worker"
        class="row justify-content-around"
      >
        <div
          class="col-xl col-lg-3 col-md-3 col-sm-5 px-1 pb-4 mb-4 text-left"
          v-for="status in statuses"
          :key="status"
          :to="status"
        >
          <Cards
            class="test-background pt-1 pb-1"
            :orders="orders"
            :status="status"
            :worker="worker"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cards from "@/components/app/Cards";
import Navbar from "@/components/app/Navbar";
import { bus } from "@/main";
import { mdbTooltip, mdbIcon } from "mdbvue";

export default {
  components: {
    Cards,
    Navbar,
    mdbTooltip,
    mdbIcon,
  },
  data() {
    return {
      statuses: [
        "Передан дизайнеру",
        "Правки",
        "Черновик",
        "Согласование",
        "Ждём 2 предоплату",
        "Печать СПб",
        "Печать МОСКВА",
      ],
      orders: [],
      worker: "",
      OrderRefresh: 0,
    };
  },
  created() {
    bus.$on("test", (data) => {
      let orderRefresh;
      this.orders = this.orders.filter((item) => {
        if (item.internalNumber === data.id) {
          orderRefresh = item;
          orderRefresh.orderStatus = data.status;
        } else {
          return true;
        }
      });
      function setStatus(orders) {
        this.orders = orders.push(orderRefresh);
      }
      setTimeout(setStatus, 500, this.orders);
    });
  },
  async mounted() {
    this.orders = await request("http://45.84.227.225/api/contacts");
    // this.orders = await request("http://45.84.227.225/api/orders");

    this.worker = await localStorage.getItem("user-status");
  },
};

async function request(url, method = "GET", data = null) {
  try {
    const headers = {};
    let body;

    if (data) {
      headers["Content-Type"] = "application/json";
      body = JSON.stringify(data);
    }

    const response = await fetch(url, {
      method,
      headers,
      body,
    });

    return await response.json();
  } catch (e) {
    console.warn("Error:", e.message);
  }
}
</script>


