<template>
  <div>
    <div
      class="row justify-content-between"
      @click.self="toggle = !toggle"
      style="cursor: pointer"
    >
      <div @click="toggle = !toggle" class="order_click_id hidden-card ml-4">
        <mdb-icon
          v-if="order.urgent"
          class="order_TIME mr-1"
          icon="clock "
          size="sm"
        />

        <mdb-icon
          v-if="order.overUrgent"
          class="order_TIME mr-1"
          icon="fire"
          size="md"
          slot="reference"
        />

        <span class="span-order">{{ order.id }}</span>
      </div>

      <!-- макеты -->
      <div v-if="status === 'Печать СПб'">
        <!-- макет 1 -->
        <mdb-tooltip trigger="hover" :options="{ placement: 'top' }">
          <span slot="tip">Открыть макет 1</span>
          <mdb-icon
            icon="print"
            size="sm"
            class="zoom_item icon-margin"
            slot="reference"
            @click.native="printMaket(order.maket1)"
          />
        </mdb-tooltip>
        <!-- макет 2 -->
        <span v-if="order.maket2">
          <mdb-tooltip
            v-for="maket2 in order.maket2"
            :key="maket2.num"
            trigger="hover"
            :options="{ placement: 'top' }"
          >
            <span slot="tip">Открыть макет {{ maket2.num }}</span>
            <mdb-icon
              icon="print"
              size="sm"
              class="zoom_item icon-margin"
              slot="reference"
              @click.native="printMaket(maket2.linkMaket)"
            />
          </mdb-tooltip>
        </span>
      </div>

      <div v-if="status === 'Печать МОСКВА'">
        <!-- макет 1 -->
        <mdb-tooltip trigger="hover" :options="{ placement: 'top' }">
          <span slot="tip">Открыть макет 1</span>
          <mdb-icon
            icon="print"
            size="sm"
            class="zoom_item mr-4"
            slot="reference"
            @click.native="printMaket(order.maket1)"
          />
        </mdb-tooltip>
        <!-- макет 2 -->
        <span v-if="order.maket2">
          <mdb-tooltip
            v-for="maket2 in order.maket2"
            :key="maket2.num"
            trigger="hover"
            :options="{ placement: 'top' }"
          >
            <span slot="tip">Открыть макет {{ maket2.num }}</span>
            <mdb-icon
              icon="print"
              size="sm"
              class="zoom_item mr-4"
              slot="reference"
              @click.native="printMaket(maket2.linkMaket)"
            />
          </mdb-tooltip>
        </span>
      </div>

      <!-- передан дизайнеру -->
      <mdb-tooltip
        v-if="status === 'Передан дизайнеру'"
        trigger="hover"
        :options="{ placement: 'top' }"
      >
        <span slot="tip">Сменить статус</span>
        <mdb-icon
          icon="check-circle"
          size="sm"
          class="order_ok_icon mr-4"
          slot="reference"
          @click.native="modal_peredan = true"
        />
        <mdb-modal
          :show="modal_peredan"
          @close="modal_peredan = false"
          class="mt-5"
        >
          <mdb-modal-body class="my-3 text-center">
            <h3 class="mb-4">Сменить статус</h3>
            <mdb-btn
              @click.native="updateOrder(order, 116846, new Date(), false)"
              color="success"
              size="lg"
              >Картина нарисована
            </mdb-btn>
          </mdb-modal-body>
        </mdb-modal>
      </mdb-tooltip>

      <!-- правки -->
      <mdb-tooltip
        v-if="status === 'Правки'"
        trigger="hover"
        :options="{ placement: 'top' }"
      >
        <span slot="tip">Сменить статус</span>
        <mdb-icon
          icon="check-circle"
          size="sm"
          class="order_ok_icon mr-4"
          slot="reference"
          @click.native="modal_pravki = true"
        />
        <mdb-modal
          :show="modal_pravki"
          @close="modal_pravki = false"
          class="mt-5"
        >
          <mdb-modal-body class="my-3 text-center">
            <h3 class="mb-4">Сменить статус</h3>
            <mdb-btn
              @click.native="updateOrder(order, 116846, 0, true)"
              color="success"
              size="lg"
              >Картина нарисована</mdb-btn
            >
          </mdb-modal-body>
        </mdb-modal>
      </mdb-tooltip>

      <!-- черновик -->
      <mdb-tooltip
        v-if="status === 'Черновик'"
        trigger="hover"
        :options="{ placement: 'top' }"
      >
        <span slot="tip">Сменить статус</span>
        <mdb-icon
          icon="check-circle"
          size="sm"
          class="order_ok_icon mr-4"
          slot="reference"
          @click.native="modal_chernovik = true"
        />
        <mdb-modal
          :show="modal_chernovik"
          @close="modal_chernovik = false"
          class="mt-5"
        >
          <mdb-modal-body class="my-3 text-center">
            <h3 class="mb-4">Сменить статус</h3>
            <mdb-btn
              @click.native="updateOrder(order, 116846, 0, false)"
              color="success"
              size="lg"
              >Картина нарисована</mdb-btn
            >
          </mdb-modal-body>
        </mdb-modal>
      </mdb-tooltip>

      <!-- печать спб -->
      <mdb-tooltip
        v-if="status === 'Печать СПб'"
        trigger="hover"
        :options="{ placement: 'top' }"
      >
        <span slot="tip">Сменить статус</span>
        <mdb-icon
          icon="check-circle"
          size="sm"
          class="order_ok_icon mr-4"
          slot="reference"
          @click.native="modal_print = true"
        />
        <mdb-modal
          :show="modal_print"
          @close="modal_print = false"
          class="mt-5"
        >
          <mdb-modal-body class="my-3 text-center">
            <h3 class="mb-4">Сменить статус</h3>
            <mdb-btn
              @click.native="updateOrder(order, 116847, 0, false)"
              color="success"
              size="lg"
              >Прислал на печать</mdb-btn
            >
          </mdb-modal-body>
        </mdb-modal>
      </mdb-tooltip>
    </div>
    <!-- конец открытой части -->

    <div v-show="toggle" class="smallerSpan pl-2">{{ order.days }}</div>

    <!-- корзина -->
    <span
      class="card card_refresh px-2 py-2 hidden-card-item text-class"
      v-html="order.basket"
      v-show="toggle"
      v-if="iconRefresh"
    >
    </span>

    <div v-else class="col-12 card px-2 mb-2" v-show="toggle">
      <div
        class="row justify-content-around basket-class"
        v-for="basket in order.basket"
        :key="basket.numberBasket"
      >
        <span
          class="
            col-9
            card_no_refresh
            text-class
            hidden-card-item
            pt-1
            pb-2
            px-1
          "
        >
          {{ basket.product }}
        </span>
        <div
          class="
            col-1
            card_no_refresh
            text-class
            py-1
            hidden-card-item
            px-0
            basket_number
          "
        >
          {{ basket.productQ }}
        </div>
      </div>

      <mdb-tooltip
        v-if="
          worker === '1594980059793 , 1612535706819' ||
          worker === '1600187208371 , 1612419274053'
        "
        trigger="hover"
        :options="{ placement: 'bottom' }"
        class="text-right"
      >
        <mdb-icon
          @click.native="sendClientMess(order.clientId)"
          class="zoom_item"
          far
          icon="envelope"
          size="md"
          slot="reference"
        />
        <span slot="tip">Написать клиенту</span>
      </mdb-tooltip>
    </div>

    <!-- комментарий -->
    <h6
      v-if="order.comment"
      v-show="toggle"
      class="hidden-card-item px-2 h7"
      v-html="order.comment"
    ></h6>

    <!-- если в комментарии ссылка -->
    <div v-if="order.commentLink" v-show="toggle">
      <span
        v-for="link in order.commentLink"
        :key="link"
        @click="printMaket(link)"
        class="link_maket px-2 h7"
        style="cursor: pointer"
        >Ссылка
      </span>
    </div>

    <!-- даты и менеджер-->
    <CardIcons v-show="toggle" :order="order" :status="status" />

    <mdb-modal :show="modalPrint" @close="modalPrint = false" class="mt-5">
      <mdb-modal-header class="justify-content-center py-4">
        Нет макета
      </mdb-modal-header>
    </mdb-modal>

    <mdb-modal
      :show="modalSendMessVk"
      @close="modalSendMessVk = false"
      class="mt-5"
    >
      <mdb-modal-header class="justify-content-center py-4">
        У клиента не указан Вконтакте
      </mdb-modal-header>
    </mdb-modal>

    <mdb-modal
      :show="modalSendMessErr"
      @close="modalSendMessErr = false"
      class="mt-5"
    >
      <mdb-modal-header class="justify-content-center py-4">
        У клиента не указан Вконтакте
      </mdb-modal-header>
    </mdb-modal>
  </div>
</template>

<script>
import CardIcons from "@/components/app/CardIcons";
import { bus } from "@/main";
import {
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbBtn,
  mdbIcon,
  mdbTooltip,
} from "mdbvue";

export default {
  props: ["order", "status"],
  components: {
    CardIcons,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    mdbIcon,
    mdbTooltip,
  },
  data() {
    return {
      toggle: false,
      modalPrint: false,
      modalSendMessVk: false,
      modalSendMessErr: false,
      modal_pravki: false,
      modal_peredan: false,
      modal_chernovik: false,
      modal_print: false,
      iconRefresh: false,
      hidden_statuses_card_status: false,
      worker: "",
    };
  },
  created() {
    bus.$on("cards_hidden", (data) => {
      this.toggle = data;
    });
    bus.$on("icon", (data) => {
      this.iconRefresh = data;
    });
  },
  methods: {
    async updateOrder(order, status, date, counter) {
      let id = this.order.id;
      let orderStatus, customFieldDate, customFieldCounter;
      // статусы
      if (status === 116846) {
        orderStatus = { id: 116846, name: "Картина нарисована" };
        this.modal_peredan = false;
        this.modal_pravki = false;
        this.modal_chernovik = false;
      }
      if (status === 116847) {
        orderStatus = { id: 116847, name: "Прислал на печать" };
        this.modal_print = false;
      }
      // дата портрен нарисован
      if (date !== 0) {
        let dateYMD = date.toISOString().substr(0, 10).split("-");
        customFieldDate = {
          fieldId: 1381,
          value: date.toISOString().substr(0, 10),
          valueAsText: `${Number(dateYMD[2])} ${monthText[dateYMD[1] - 1]} ${
            dateYMD[0]
          }`,
        };
      }
      // счетчики правок
      if (counter) {
        customFieldCounter = {
          fieldId: 1728,
          value: counter,
        };
      }
      let updated = await request(
        `http://45.84.227.225/api/contacts/${id}`,
        // `http://45.84.227.225/api/bs/orders/${id}`,

        "PUT",
        {
          orderStatus,
          customFieldDate,
          customFieldCounter,
        }
      );
      if (updated) {
        order.status = updated;
        bus.$emit("test", order);
      }
    },
    async sendClientMess(clientId) {
      let updated = await request(`http://45.84.227.225/api/clients`, "PUT", {
        // let updated = await request(
        //   `http://45.84.227.225/api/bs/clients`,
        //   "PUT",
        //   {
        clientId,
      });
      if (updated) {
        if (updated === `ошибка`) {
          this.modalSendMessErr = true;
        } else if (updated === `нет вк`) {
          this.modalSendMessVk = true;
        } else {
          var windowObjectReference = window.open(
            `https://vk.com/gim100602540?sel=${updated}`,
            "_blank",
            "menubar,location,resizable,scrollbars,status"
          );
        }
      }
    },
    printMaket(maket) {
      if (maket.length) {
        var windowObjectReference = window.open(
          maket,
          "_blank",
          "menubar,location,resizable,scrollbars,status"
        );
      } else {
        this.modalPrint = true;
      }
    },
  },
  async mounted() {
    this.worker = await localStorage.getItem("user-status");
  },
};

var monthText =
  "января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря".split(
    ","
  );

async function request(url, method = "GET", data = null) {
  try {
    const headers = {};
    let body;

    if (data) {
      headers["Content-Type"] = "application/json";
      body = JSON.stringify(data);
    }

    const response = await fetch(url, {
      method,
      headers,
      body,
    });

    return await response.json();
  } catch (e) {
    console.warn("Error:", e.message);
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lora&family=Nanum+Gothic&family=Open+Sans&display=swap");
</style>