<template>
  <div class="text-class px-0">
    <div class="row hidden-card-item text-roboto">
      <div :class="{ hidden: order.sizeLargerHidden }" class="col-12 ml-1 mb-1">
        <mdb-tooltip trigger="hover" :options="{ placement: 'right' }">
          <mdb-icon
            icon="expand-arrows-alt"
            size="lg"
            class="pr-2"
            slot="reference"
          />
          <span slot="tip">Размер больше</span>
        </mdb-tooltip>
        <span> {{ order.sizeLarger }}</span>
      </div>

      <div class="col-12 ml-1 mb-1">
        <mdb-tooltip trigger="hover" :options="{ placement: 'right' }">
          <mdb-icon
            v-if="
              status === 'Передан дизайнеру' ||
              status === 'Правки' ||
              status === 'Черновик'
            "
            icon="user-edit"
            size="lg"
            class="date_maket_icon zoom_item pr-1"
            slot="reference"
            @click.native="modal_date_date = true"
            style="cursor: pointer"
          />
          <mdb-icon
            v-else
            icon="user-edit"
            size="lg"
            class="pr-1"
            slot="reference"
          />
          <span slot="tip">Дата готовности макета</span>

          <mdb-modal
            size="md"
            :show="modal_date_date"
            @close="modal_date_date = false"
            class="mt-5"
          >
            <mdb-modal-header class="text-center">
              <mdb-modal-title>Не успеваю закончить макет</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body class="my-3"> </mdb-modal-body>
            <mdb-modal-footer class="justify-content-around">
              <mdb-btn
                @click.native="updateOrder(order, 119139, 0, true)"
                color="success"
                >Перенести заказ
              </mdb-btn>
            </mdb-modal-footer>
          </mdb-modal>
        </mdb-tooltip>

        <span
          :class="{
            dateMaketToday: order.dateToday,
            dateMaketFire: order.dateFire,
          }"
          class="test-text"
          >{{ order.dateMaket.valueAsText }}</span
        >
      </div>

      <div class="col-12 ml-1 mb-1" :class="{ hidden: order.datePlanHidden }">
        <mdb-tooltip trigger="hover" :options="{ placement: 'right' }">
          <mdb-icon
            icon="user-clock"
            size="lg"
            class="date_plan_icon pr-1"
            slot="reference"
          />
          <span slot="tip">Дата план </span>
        </mdb-tooltip>

        <span class="pr-1">{{ order.datePlan }}</span>
      </div>

      <div class="col ml-1 mb-1">
        <mdb-tooltip trigger="hover" :options="{ placement: 'right' }">
          <mdb-icon
            icon="user-friends"
            size="lg"
            class="pr-1"
            slot="reference"
          />
          <span slot="tip">Менеджер</span>
        </mdb-tooltip>

        <span class="">{{ order.manager }}</span>
      </div>

      <div class="w-100"></div>
      <div class="col ml-1">
        <mdb-tooltip trigger="hover" :options="{ placement: 'right' }">
          <mdb-icon icon="truck" size="lg" class="pr-1" slot="reference" />
          <span slot="tip">Число отправки и способ доставки</span>
        </mdb-tooltip>
        <span>{{ order.dateShip }}</span>
        <br />
        <span class="span_delivery">{{ order.deliveryMethod }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";
import {
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbBtn,
  mdbIcon,
  mdbDatePicker,
  mdbTooltip,
} from "mdbvue";

export default {
  props: ["order", "status"],
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    mdbIcon,
    mdbTooltip,
  },
  data() {
    return {
      modal_date_date: false,
      iconRefresh: false,
    };
  },
  created() {
    bus.$on("icon", (data) => {
      this.iconRefresh = data;
    });
  },

  methods: {
    // async updateDateMaket(order, date, counter) {
    //   let id = this.order.id;
    //   let dateYMD = date.split("-");
    //   let customFieldDate = {
    //     fieldId: 86,
    //     value: date,
    //     valueAsText: `${Number(dateYMD[2])} ${monthText[dateYMD[1] - 1]} ${
    //       dateYMD[0]
    //     }`,
    //   };
    //   let customFieldCounter = {
    //     fieldId: 1725,
    //     value: counter,
    //   };
    //   const updated = await request(
    //     `http://45.84.227.225/api/contacts/${id}`,
    //     "PUT",
    //     {
    //       customFieldDate,
    //       customFieldCounter,
    //     }
    //   );
    //   if (updated) {
    //     order.dateMaket.valueAsText = updated.valueAsText.substring(
    //       0,
    //       updated.valueAsText.length - 5
    //     );
    //     this.modalDate = false;
    //   }
    // },
    async updateOrder(order, status, counter) {
      let id = this.order.id;
      let orderStatus;
      // статусы
      if (status === 119139) {
        orderStatus = { id: 119139, name: "Перенос макета на другую дату" };
        this.modal_date_date = false;
      }

      // счетчики переносов
      let customFieldCounter = {
        fieldId: 1725,
        value: counter + 1,
      };
      let updated = await request(
        `http://45.84.227.225/api/contacts/${id}`,
        "PUT",
        {
          orderStatus,
          customFieldCounter,
        }
      );
      if (updated) {
        order.status = updated;
        bus.$emit("test", order);
      }
    },
  },
};

var monthText =
  "января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря".split(
    ","
  );
async function request(url, method = "GET", data = null) {
  try {
    const headers = {};
    let body;

    if (data) {
      headers["Content-Type"] = "application/json";
      body = JSON.stringify(data);
    }

    const response = await fetch(url, {
      method,
      headers,
      body,
    });

    return await response.json();
  } catch (e) {
    console.warn("Error:", e.message);
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
</style>